/* eslint-disable import/prefer-default-export */
/* eslint-disable quotes */
import LazyLoad from 'vanilla-lazyload';

export function initLazyLoad() {
  const lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy",
    threshold: 400,
    thresholds: "100% 20%",
  });

  lazyLoadInstance.update();
}
