/* eslint-disable */
export function tabClick() {
  const links = [...document.querySelectorAll(".story__tab-content a")];

  if (typeof links !== "undefined" && links != null) {
    links.forEach((item) => {
      item.addEventListener("click", () => {
        window.open(item.href, "_self");
      });
    });
  }
}
