/* eslint-disable no-inner-declarations */
/* eslint-disable no-use-before-define */
/* eslint-disable no-plusplus */
/* eslint-disable no-new */
/* eslint-disable no-param-reassign */
/* eslint-disable new-cap */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-restricted-properties */
/* eslint-disable no-undef */

import UIkit from 'uikit';

import { initLazyLoad } from './vendors/lazyLoad';

import { hero } from './components/hero';
import { formSubmit } from './components/newsletter';
import { formSubmitDemo, goToStep2 } from './components/demo';
import { tabClick } from './components/click';
import countUp from './components/animate';

window.UIkit = UIkit;

const submenu = document.querySelectorAll('.sub-menu');

if (typeof submenu !== 'undefined' && submenu != null) {
  submenu.forEach((item, index) => {
    item.classList.add(`dropdown-${index}`);
    UIkit.dropdown(item, {
      mode: 'hover',
      duration: 400,
      pos: 'bottom-center',
    });
  });
}

initLazyLoad();
hero();
tabClick();
formSubmit();
formSubmitDemo();
goToStep2();

const hamburger = document.getElementById('hamburger');
const preloader = document.getElementById('preloaderGlobal');

if (typeof hamburger !== 'undefined' && hamburger != null) {
  // eslint-disable-next-line func-names
  hamburger.addEventListener('click', function () {
    this.classList.toggle('header__hamburger--open');
  });
}

window.addEventListener('DOMContentLoaded', () => {
  preloader.classList.add('hide', 'preloader--fetch');
});

const addStyleToHeader = () => {
  const header = document.querySelector('header');

  header.classList.add('header--toast');
};

function showToast() {
  const toast = document.getElementById('notifications-wrapper');

  if (typeof toast !== 'undefined' && toast != null) {
    toast.style.display = 'block';

    sessionStorage.setItem('toastShown', 'true');
  }

  if (typeof toast === 'undefined' || toast === null) {
    const header = document.querySelector('header');

    header.classList.add('header--toast');
  }
}

function hideToast() {
  const toast = document.getElementById('notifications-wrapper');

  toast.style.display = 'none';
  addStyleToHeader();

  sessionStorage.setItem('toastShown', 'false');
}

function checkToastStatus() {
  const toastShown = sessionStorage.getItem('toastShown');
  const toastClick = sessionStorage.getItem('toastClick');

  if (!toastShown || !toastClick || toastClick === 'false') {
    showToast();
  }

  if (toastShown === 'false') {
    addStyleToHeader();
    const toast = document.getElementById('notifications-wrapper');

    toast.style.display = 'none';
  }
}

window.onload = checkToastStatus;

const toastButton = document.getElementById('close-notification');

if (typeof toastButton !== 'undefined' && toastButton != null) {
  // eslint-disable-next-line func-names
  toastButton.addEventListener('click', () => {
    hideToast();
    sessionStorage.setItem('toastClick', 'true');
  });
}

const boxes = document.querySelectorAll('.partners-plan__box-plan');
const lines = document.querySelectorAll('.partners-plan__box-single-box');

if (
  typeof boxes !== 'undefined'
  && boxes != null && boxes.length
  && typeof lines !== 'undefined'
  && lines != null
) {
  // const currentlyActive = -1;

  function addActiveClassAndFetchBoxes() {
    const boxes = document.querySelectorAll(
      '.partners-plan__box--big .partners-plan__box-plan',
    );
    const lines = document.querySelectorAll('.partners-plan__box-single-box');

    function addActiveClass(index) {
      boxes.forEach((box) => box.classList.remove('active'));

      if (boxes[index]) {
        boxes[index].classList.add('active');
      }
      if (lines[index]) {
        lines[index].classList.add('active');
      }

      if (index === 2) {
        setTimeout(() => {
          lines.forEach((line) => line.classList.remove('active'));
        }, 1500);
      }
    }
    let currentIndex = 0;

    setInterval(() => {
      addActiveClass(currentIndex);
      currentIndex = (currentIndex + 1) % boxes.length;
    }, 2000);
  }

  addActiveClassAndFetchBoxes();
  // boxes.forEach((box, index) => {
  //   box.addEventListener('mouseenter', () => {
  //     updateActiveClasses(index);
  //   });
  // });

  // lines.forEach((line, index) => {
  //   line.addEventListener('mouseenter', () => {
  //     updateActiveClasses(index);
  //   });
  // });

  // function updateActiveClasses(newActive) {
  //   clearInterval(intervalId); // Zatrzymaj poprzedni interwał, jeśli istnieje
  //   intervalId = setInterval(() => {
  //     if (newActive > currentlyActive) {
  //       for (let i = currentlyActive + 1; i <= newActive; i++) {
  //         boxes[i].classList.add('active');
  //         lines[i].classList.add('active');
  //       }
  //     } else {
  //       for (let i = currentlyActive; i > newActive; i--) {
  //         boxes[i].classList.remove('active');
  //         lines[i].classList.remove('active');
  //       }
  //     }

  //     currentlyActive = newActive;
  //   }, 2000);
  // }

  // document
  //   .querySelector('.partners-plan__box')
  //   .addEventListener('mouseleave', () => {
  //     lines.forEach((box) => box.classList.remove('active'));
  //     boxes.forEach((box) => box.classList.remove('active'));
  //     boxes[0].classList.add('active');
  //     currentlyActive = -1;
  //   });
}
UIkit.scrollspy('#cash');
UIkit.util.on('#cash', 'inview', () => {
  const counters = document.querySelectorAll('#cach-count-up');
  const speed = 100;

  counters.forEach((counter) => {
    const animate = () => {
      const value = +counter.getAttribute('data-number');
      const data = +counter.innerText;

      const time = value / (speed + speed);
      if (data < value) {
        counter.innerText = Math.ceil(data + time);
        setTimeout(animate, 10);
      } else {
        counter.innerText = value;
      }
    };

    animate();
  });
});

const accordionList = document.querySelectorAll('.accordion-item');

accordionList.forEach((event) => event.addEventListener('click', () => {
  if (event.classList.contains('active')) {
    event.classList.remove('active');
  } else {
    accordionList.forEach((event2) => event2.classList.remove('active'));
    event.classList.add('active');
  }
}));

const dataModules = [...document.querySelectorAll('[data-module="countup"]')];

dataModules.forEach((element) => {
  element.dataset.module.split(' ').forEach(() => {
    // eslint-disable-next-line no-new, new-cap
    new countUp(element);
  });
});
