/* eslint-disable */
export const goToStep = (stepNumber) => {
  document.querySelectorAll(".step").forEach((step) => {
    step.classList.remove("active");
  });
  document.getElementById("step" + stepNumber).classList.add("active");

  // if (stepNumber === 3) {
   
  //     Calendly.initInlineWidget({
  //       url: "https://calendly.com/jakub-kowalik-expertsender",
  //       parentElement: document.getElementById("calendly-embed"),
  //       resize: true,
  //     });
  // }
};

export function formSubmitDemo() {
  const form = document.getElementById("form-demo");

  if (typeof form !== "undefined" && form != null) {
    const handleSubmit = (e) => {
      const language = document.documentElement.lang;
      e.preventDefault();
      const emailInput = document.querySelector("#form-demo #email");
      const nameInput = document.querySelector("#form-demo #name");
      const phoneInput = document.querySelector("#form-demo #phone");
      const checkbox = document.querySelector(
        "#form-demo .form__checkbox input"
      );
      if (checkbox.checked) {
        const button = document.querySelector("#form-demo button");

        let queryString = {};
        const urlParams = sessionStorage.getItem("params");

        const searchParams = new URLSearchParams(urlParams);

        if (searchParams) {
          searchParams.forEach(async (value, key) => {
            // console.log(rating);
            queryString = { ...queryString, [key]: value };
          });
        }
        console.log(queryString);

        emailInput.setAttribute("disabled", !0);
        nameInput.setAttribute("disabled", !0);
        phoneInput.setAttribute("disabled", !0);
        button.setAttribute("disabled", !0);
        form.classList.add("opacity-40", "cursor-default");
        goToStep(3);
        // fetch("https://hooks.zapier.com/hooks/catch/546132/3mtrtz5/", {
        // fetch(
        //   language === "pl-PL"
        //     ? "https://hooks.zapier.com/hooks/catch/546132/3mtrtz5/"
        //     : "https://hooks.zapier.com/hooks/catch/546132/394pq7a/",
        //   {
        //     method: "post",
        //     headers: {
        //       Accept: "application/json",
        //     },
        //     body: JSON.stringify({
        //       email: document.querySelector("#form-demo #email").value,
        //       name: document.querySelector("#form-demo #name").value,
        //       phone: document.querySelector("#form-demo #phone").value,
        //       ...queryString,
        //     }),
        //   }
        // )
        //   .then((e) => {
        //     emailInput.removeAttribute("disabled");
        //     nameInput.removeAttribute("disabled");
        //     phoneInput.removeAttribute("disabled");
        //     button.removeAttribute("disabled");
        //     if (language === "pl-PL") {
        //       UIkit.notification({
        //         message: "Wiadomość została wysłana",
        //         pos: "bottom-center",
        //         timeout: 5000,
        //         status: "success",
        //       });
        //     } else {
        //       UIkit.notification({
        //         message: "Message was sent ",
        //         pos: "bottom-center",
        //         timeout: 5000,
        //         status: "success",
        //       });
        //     }
        //     emailInput.value = "";
        //     nameInput.value = "";
        //     phoneInput.value = "";

        //     if (language === "pl-PL") {
        //       return window.location.replace(
        //         `https://expertsender.com/pl/demo/dziekujemy/`,
        //         "_self"
        //       );
        //     } else {
        //       return window.location.replace(
        //         `https://expertsender.com/demo/thank-you/`,
        //         "_self"
        //       );
        //     }
        //   })
        //   .catch(() => {
        //     if (language === "pl-PL") {
        //       UIkit.notification({
        //         message: "Wystąpił błąd podczas wysyłania danych",
        //         pos: "bottom-center",
        //         timeout: 5000,
        //         status: "warning",
        //       });
        //     } else {
        //       UIkit.notification({
        //         message: "An error occurred while sending data ",
        //         pos: "bottom-center",
        //         timeout: 5000,
        //         status: "warning",
        //       });
        //     }
        //   });
      } else {
        if (language === "pl-PL") {
          UIkit.notification({
            message: "Wyraź zgodę na przetwarzanie danych osobowych",
            pos: "bottom-center",
            timeout: 5000,
            status: "warning",
          });
        } else {
          UIkit.notification({
            message: "Consent to the processing of personal data ",
            pos: "bottom-center",
            timeout: 5000,
            status: "warning",
          });
        }
      }
    };
    form.addEventListener("submit", handleSubmit);
  }
}

export const goToStep2 = () => {
  const buttonMarketingAutomation = document.getElementById(
    "marketing-automation"
  );
  if (
    typeof buttonMarketingAutomation !== "undefined" &&
    buttonMarketingAutomation != null
  ) {
    document
      .getElementById("marketing-automation")
      .addEventListener("click", () => {
        document.getElementById("solution").value =
          "Marketing Automation for Ecommerce";
        goToStep(2);
      });
  }
  const buttonEmailMarketing = document.getElementById("email-marketing");
  if (
    typeof buttonEmailMarketing !== "undefined" &&
    buttonEmailMarketing != null
  ) {
    document.getElementById("email-marketing").addEventListener("click", () => {
      document.getElementById("solution").value = "Email Marketing Platform";
      goToStep(2);
    });
  }
};
