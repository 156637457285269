/* eslint-disable */
export function hero() {
  const hoverItems = document.querySelectorAll(".hero__item");
  const content = document.querySelectorAll(".hero__content");

  if (typeof hoverItems !== "undefined" && hoverItems != null) {
    hoverItems.forEach((item) => {
      item.addEventListener("mouseover", () => {
        const element = Array.from(content).find(
          (el) => el.dataset.id === item.dataset.id
        );
        if (element) {
          document.querySelectorAll(".hero-active").forEach((el) => {
            el.classList.remove("hero-active");
          });
          document
            .querySelectorAll(".uk-animation-slide-left")
            .forEach((el) => {
              el.classList.remove("uk-animation-slide-left");
            });
          document.querySelectorAll(".uk-animation-slide-top").forEach((el) => {
            el.classList.remove("uk-animation-slide-top");
          });
          element
            .querySelector(".hero__col")
            .classList.add("uk-animation-slide-left");
          element
            .querySelector(".hero__col--image")
            .classList.add("uk-animation-slide-top");
          element.classList.add("hero-active");
        }
      });
    });
  }
}
