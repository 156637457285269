/* eslint-disable */
export function formSubmit() {
  const form = document.getElementById("newsletter-form");

  if (typeof form !== "undefined" && form != null) {
    const handleSubmit = (e) => {
      const language = document.documentElement.lang;
      e.preventDefault();
      const input = document.querySelector("#newsletter-form #email");
      const checkbox = document.querySelector(
        ".newsletter .form__checkbox input"
      );
      if (checkbox.checked) {
        const button = document.querySelector("#newsletter-form button");

        input.setAttribute("disabled", !0);
        button.setAttribute("disabled", !0);

        form.classList.add("opacity-40", "cursor-default");
        // fetch("https://hooks.zapier.com/hooks/catch/546132/3m5vn73/", {
        fetch(
          language === "pl-PL"
            ? "https://hooks.zapier.com/hooks/catch/546132/3m5vn73/"
            : "https://hooks.zapier.com/hooks/catch/546132/394nstg/",
          {
            method: "post",
            headers: {
              Accept: "application/json",
            },
            body: JSON.stringify({
              email: document.querySelector("#newsletter-form #email").value,
            }),
          }
        )
          .then((e) => {
            input.removeAttribute("disabled");
            button.removeAttribute("disabled");
            if (language === "pl-PL") {
              UIkit.notification({
                message: "Pozytywnie zapisano do newslettera ",
                pos: "bottom-center",
                timeout: 5000,
                status: "success",
              });
            } else {
              UIkit.notification({
                message: "Positive subscription to the newsletter ",
                pos: "bottom-center",
                timeout: 5000,
                status: "success",
              });
            }
            input.value = "";
          })
          .catch(() => {
            if (language === "pl-PL") {
              UIkit.notification({
                message: "Wystąpił błąd podczas zapisywania do newslettera ",
                pos: "bottom-center",
                timeout: 5000,
                status: "warning",
              });
            } else {
              UIkit.notification({
                message:
                  "An error occurred while subscribing to the newsletter ",
                pos: "bottom-center",
                timeout: 5000,
                status: "warning",
              });
            }
          });
      } else {
       if (language === "pl-PL") {
         UIkit.notification({
           message: "Wyraź zgodę na przetwarzanie danych osobowych ",
           pos: "bottom-center",
           timeout: 5000,
           status: "warning",
         });
       } else {
         UIkit.notification({
           message: "Consent to the processing of personal data ",
           pos: "bottom-center",
           timeout: 5000,
           status: "warning",
         });
       }
      }
    };
    form.addEventListener("submit", handleSubmit);
  }
}
